import { useEffect, useState } from 'react';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Error from 'next/error';
import getPage from '@framework/api/operations/get-page';
import getNav from '@framework/api/operations/get-nav';
import decoratePageContent from '@utils/decorate-page-content';
import trackError from '@utils/amplitude/track/error';
import { useHistory } from '@utils/history';
import Layout from '@components/Layout';
import Page from '@components/Page';
import { REVALIDATE_VALUES } from '@constants';

// Separating this logic from the custom _error page
// so that we don't record an exception in Sentry for 404s

export const getStaticProps: GetStaticProps = async ({
  locale,
  locales,
  preview,
}) => {
  const [page, nav] = await Promise.all([
    getPage({ slug: '404', locale, preview }),
    getNav({ slug: 'main', locale, preview }),
  ]);

  const { content, ...rest } = page;

  const decoratedContent = await decoratePageContent(content);

  return {
    props: {
      preview: preview || false,
      locale,
      locales: JSON.stringify(locales),
      page: { content: decoratedContent, ...rest },
      nav,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

const NotFound: InferGetStaticPropsType<typeof getStaticProps> = ({ page }) => {
  const { last: lastPage } = useHistory();
  const [hasBeenTrackedInAmplitude, setHasBeenTrackedInAmplitude] =
    useState(false);

  useEffect(() => {
    let timeout = null;

    if (!hasBeenTrackedInAmplitude) {
      timeout = setTimeout(() => {
        trackError({
          errorType: 'browse',
          errorName: 'Not found',
          errorCode: 404,
          referrer: lastPage,
        });

        setHasBeenTrackedInAmplitude(true);
      }, 1);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [hasBeenTrackedInAmplitude, lastPage]);

  return !page ? <Error statusCode={404} /> : <Page page={page} />;
};

NotFound.Layout = Layout;

export default NotFound;
